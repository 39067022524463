import { useRepairFilter } from "@/hooks/useRepairFilter";
import { NextPage } from "next";
import RepairTable from "../../components/RepairTable";
import { withApollo } from "../../components/WithApollo";
import { useRepairContext } from "../../contexts/RepairContext";
import { InnerProcessingComponent as FinishedComponent } from "./finished";
import { isBefore, subMonths } from "date-fns";
import sortBy from "lodash/sortBy";
import { useEffect } from "react";

/**
 * Require a shipping label after this date except for rejected/irrepairable repairs
 * These include the half year before so cleanup can happen
 */
const cutOffDate = new Date("2025-01-31");
const specialCondition = (repair) => !isBefore(new Date(repair.createdAt), cutOffDate);

export const needsReturnRepairCondition = (repair) => !repair.returnLabel && specialCondition(repair);

const FinishedRepairs: NextPage = () => {
  if (typeof window === "undefined") return null;
  const { archivedLoading: loading, archivedRepairs, refetchArchived } = useRepairContext();

  useEffect(() => {
    refetchArchived();
  }, []);

  const repairs = archivedRepairs.filter(needsReturnRepairCondition);

  const [filteredRepairs] = useRepairFilter(repairs);

  return (
    <RepairTable
      repairs={sortBy(filteredRepairs, "createdAt").reverse()}
      loading={loading}
      OpenRowComponent={FinishedComponent}
      pageSize={100}
    />
  );
};

export default withApollo()(FinishedRepairs);
